import React, {useState} from "react";
import BottomMenuBar from './BottomMenuBar';
import SmallBox from './SmallBox';
import me from '../images/me.png'


const CV = () => {

    const [isEmailVisible, setisEmailVisible] = useState(false);
    const toggleEmailVisibility = () => {
      setisEmailVisible(!isEmailVisible);
    };

    return (
        <div style={styles.container}>
          <section style={styles.section}>
            <div style={styles.introductionContainer}>
              <img src={me} alt="Axel Schrotti" style={styles.profileImage} />
              <div style={styles.introductionText}>
                <h1 style={styles.name}>Axel Schrotti</h1>
                <p style={styles.title}>Developer</p>
                <p style={styles.intro}>
                I'm a passionate developer who thrives on tackling tough challenges and diving deep into the details
                 to solve complex problems. What drives me is collaborating with smart, like-minded people and fostering clear communication, because that is when I deliver my best work.
                 I believe that great teamwork and a shared commitment to problem-solving lead to the most impactful solutions.
                 Below is a selection of my most recent experiences.
                </p>
              </div>
            </div>
          </section>

          <section style={styles.section}>
            <h2 style={styles.heading}>Recent Projects</h2>
    
            <div style={styles.experienceItem}>
              <h3>Developer - Consultant</h3>
              <p style={styles.experienceCompany}>HiQ Stockholm</p>
              <p style={styles.experienceDate}>Jun 2023 - Present</p>
              <ul>
                <li>Working on autonomous operation solutions for a client in the
                transportation industry</li>
                <li>Cross-functional role in agile team ranging from testing
                to feature implementation</li>
                <li>Delivered release of integrated system serving a large-
                scale railway network</li>
                <li>Doubled close rate for tasks per sprint in one
                year</li>
              </ul>
            </div>

            <div style={styles.experienceItem}>
              <h3>Developer - Internal Project</h3>
              <p style={styles.experienceCompany}>HiQ Stockholm</p>
              <p style={styles.experienceDate}>Mar 2023 - Jun 2023</p>
              <ul>
                <li>Built an outline for a game engine to serve as the basis for an
                    introductory program for an incoming cohort of recent graduates to the company</li>
                <li>Created example implementation of game engine complete with physics simulation, rendering and other mechanics</li>
                <li>Created reusable components that function independently to allow program participants to focus on specific aspects of game engine development</li>
              </ul>
            </div>
          </section>

          <section style={styles.section}>
            <h2 style={styles.heading}>Skills</h2>
            <ul style={styles.skillsList}>
              <li style={styles.skillItem}>JavaScript / React / Redux</li>
              <li style={styles.skillItem}>C / C++</li>
              <li style={styles.skillItem}>Python</li>
              <li style={styles.skillItem}>bash</li>
              <li style={styles.skillItem}>Java</li>
              <li style={styles.skillItem}>HTML5 / CSS3 / Sass</li>
              <li style={styles.skillItem}>Node.js / Express</li>
              <li style={styles.skillItem}>Responsive Design</li>
              <li style={styles.skillItem}>Git / GitHub / CI/CD</li>
              <li style={styles.skillItem}>Agile Development / Scrum</li>
            </ul>
          </section>

          <section style={styles.section}>
            <h2 style={styles.heading}>Education</h2>
            <div style={styles.educationItem}>
              <h3>Bachelor of Arts in Computer Science</h3>
              <p style={styles.educationSchool}>Middlebury College</p>
              <p style={styles.educationDate}>2018-2023</p>
            </div>
          </section>
          {isEmailVisible && <SmallBox onClose={toggleEmailVisibility} />}
          <BottomMenuBar onShowBox={toggleEmailVisibility}/>
        </div>
      );
  };
  
  // Inline Styles
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      background: '#E5E4D7', // Warmer white color
      padding: '20px',
      borderRadius: '15px',
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
      fontFamily: '"Arial", sans-serif',
    },
    section: {
      marginBottom: '40px',
    },
    introductionContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '30px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.05)',
      animation: 'fadeIn 1s ease-out',
    },
    profileImage: {
      width: '120px',
      height: '120px',
      borderRadius: '50%',
      objectFit: 'cover',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
      marginRight: '30px', // Add spacing between image and text
    //   border: '4px solid #fdf4e3',  // Matches the new background
      animation: 'fadeIn 1.2s ease-out',
    },
    introductionText: {
      flex: 1,
    },
    name: {
      fontSize: '2.8rem',
      fontWeight: 'bold',
      color: '#333',
      marginBottom: '10px',
    },
    title: {
      fontSize: '1.3rem',
      color: '#777',
      marginBottom: '20px',
    },
    intro: {
      fontSize: '1.1rem',
      color: '#555',
      maxWidth: '600px',
    },
    heading: {
      fontSize: '2rem',
      color: '#333',
      marginBottom: '20px',
    },
    experienceItem: {
      marginBottom: '30px',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.05)',
      animation: 'fadeInUp 1s ease-out',
    },
    experienceCompany: {
      fontWeight: 'bold',
      color: '#555',
    },
    experienceDate: {
      fontStyle: 'italic',
      color: '#888',
      marginBottom: '10px',
    },
    skillsList: {
      listStyleType: 'none',
      padding: 0,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: '10px',
    },
    skillItem: {
      backgroundColor: '#fff',
      padding: '10px',
      borderRadius: '8px',
      textAlign: 'center',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.05)',
      animation: 'fadeIn 1.2s ease-out',
    },
    educationItem: {
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.05)',
      animation: 'fadeInUp 1s ease-out',
    },
    educationSchool: {
      fontWeight: 'bold',
      color: '#555',
    },
    educationDate: {
      fontStyle: 'italic',
      color: '#888',
      marginTop: '5px',
    },
  
    // Add animations
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '@keyframes fadeInUp': {
      from: { opacity: 0, transform: 'translateY(20px)' },
      to: { opacity: 1, transform: 'translateY(0)' },
    },
  };
  export default CV;