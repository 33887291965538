import React from 'react';

function GeneralInfo({ onClose }) {
    return (
      <div style={styles.box}>

        <h1 style={styles.title}>Welcome to my Webpage!</h1>
        
        <p style={styles.bodyText}>
          I am happy you have found your way to my personal webpage!
          My name is Axel, and I am a developer currently located in Stockholm, Sweden.<br />
          Here you can take a look at my CV, read about me, and reach out if you have questions.<br />
          <br />
          <strong>About this page:</strong><br />
          I used React to make this page. The images I have taken myself, and the background was generated with OpenAI's DALL·E.
          The "desktop icons" you see on the landing page are all from
          <a href="https://lineicons.com" style={styles.link} target="_blank" rel="noopener noreferrer">
          lineicons.com. 
          </a>
          <br />
          Feel free to navigate around!
        </p>
        <button onClick={onClose} style={styles.button}>Got it!</button>
      </div>
    );
  }
  
  const styles = {
    box: {
      position: 'fixed',      // Fixed positioning to float over other content
      top: '50%',             // Center vertically
      left: '50%',            // Center horizontally
      transform: 'translate(-50%, -50%)',  // Adjust position to center correctly
      width: '650px',         // Set the width of the box
      height: '300px',        // Set the height of the box
      padding: '20px',        // Add some padding
      backgroundColor: '#1B3244', // Background color
      borderRadius: '10px',   // Rounded corners
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',  // Add a shadow for effect
      textAlign: 'center',    // Center the text inside the box
      zIndex: 1000,            // Ensure it's above other elements
      color: '#fff',          // Set default text color to white
    },
    closeButton: {
      position: 'absolute',
      top: '5px',
      right: '10px',  // Close button in the top-right corner
      background: 'transparent',
      border: 'none',
      fontSize: '18px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',  // Smooth background color transition
      color: 'white',
    },
    title: {
      marginTop: '5px',   // Adjust title position
      fontSize: '24px',    // Font size for the title
      color: '#fff',       // Title text color (white)
    },
    bodyText: {
      fontSize: '16px',    // Font size for the body text
      color: '#fff',       // Body text color (white)
      marginTop: '10px',   // Space between title and body text
    },
    link: {
        color: '#00bfff',      // Light blue color for the hyperlink
        textDecoration: 'none', // Remove underline
        marginLeft: '5px',      // Space between text and link
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '5px',
        backgroundColor: '#4CAF50',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
    },
  };
  
  export default GeneralInfo;