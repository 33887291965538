import React, {useState} from 'react';
import EmailForm from './EmailForm';

function SmallBox({ onClose }) {
    return (
      <div style={styles.box}>
        <button onClick={onClose} style={styles.closeButton}>
          &times;
        </button>
        <EmailForm />
      </div>
    );
  }
  
  const styles = {
    box: {
      position: 'fixed',      // Fixed positioning to float over other content
      top: '50%',             // Center vertically
      left: '50%',            // Center horizontally
      transform: 'translate(-50%, -50%)',  // Adjust position to center correctly
      width: '100vh',         // Set the width of the box
      height: '70vh',        // Set the height of the box
      padding: '20px',        // Add some padding
      backgroundColor: '#1B3244', // Background color
      borderRadius: '10px',   // Rounded corners
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',  // Add a shadow for effect
      textAlign: 'center',    // Center the text inside the box
      zIndex: 999,           // Ensure it's above other elements
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',  // Close button in the top-right corner
      background: 'transparent',
      border: 'none',
      fontSize: '18px',
      cursor: 'pointer',
      color: 'white',
    },
  };
  
  export default SmallBox;