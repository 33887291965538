import React, {useState} from 'react';
import { useNavigate, BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import homeIcon from '../images/house-chimney.png';
import linkedinIcon from '../images/linkedin-box-fill.png';
import cvIcon from '../images/text-page.png';
import aboutMeIcon from '../images/me_circle.png';
import emailIcon from '../images/email.png';
import imageIcon from '../images/image.png';

function BottomMenuBar({ onShowBox }) {
  const navigate = useNavigate();
  
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const icons = [
    { id: 1, name: 'Home', src: homeIcon, onClick: () => navigate('../') },
    { id: 2, name: 'About me', src: aboutMeIcon, onClick: () => navigate('/about-me') },
    { id: 3, name: 'CV', src: cvIcon, onClick: () => navigate('/cv') },
    { id: 4, name: 'LinkedIn', src: linkedinIcon, onClick: () => window.location.href = 'https://www.linkedin.com/in/axel-schrotti-17b787163?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'},
    { id: 5, name: 'Email', src: emailIcon, onClick: () => onShowBox() },
    { id: 6, name: 'Photos', src: imageIcon, onClick: () => navigate('/images')},
  ];

  return (
    <div style={styles.menuBar}>
      {icons.map((icon) => (
        <div
          key={icon.id}
          style={{
            ...styles.iconContainer,
            backgroundColor: hoveredIcon === icon.id ? '#364697' : 'transparent', // Background color on hover
          }}
          onMouseEnter={() => setHoveredIcon(icon.id)}  // Set the hovered icon
          onMouseLeave={() => setHoveredIcon(null)}      // Reset the hovered icon
        >
          <img
            src={icon.src}
            alt={icon.name}
            title={icon.name}
            style={styles.icon}
            onClick={icon.onClick}
          />
        </div>
      ))}
    </div>
  );
}

// Inline styles
const styles = {
  menuBar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1px',
    backgroundColor: '#1e1e1e',
    width: '100%',
    height: '60px', // Height similar to a taskbar
    position: 'fixed',
    bottom: 0,
    left: 0,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px',
    transition: 'background-color 0.3s',  // Smooth background color transition
    cursor: 'pointer',
  },
  icon: {
    width: '50px',
    height: '50px',
  },
};

export default BottomMenuBar;
