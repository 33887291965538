import React, {useState} from 'react';
import { useNavigate, BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import homeIcon from '../images/computer.png';
import linkedinIcon from '../images/linkedin-box-fill.png';
import cvIcon from '../images/text-page.png';
import aboutMeIcon from '../images/me_circle.png';
import emailIcon from '../images/email.png';
import imageIcon from '../images/image.png';

function DesktopIcons({ onShowEmail, onShowInfo }) {
    const navigate = useNavigate();
    const [hoveredIcon, setHoveredIcon] = useState(null); // Track hovered icon ID
  
    const icons = [
      { id: 1, name: 'Welcome', src: homeIcon, onClick: () => onShowInfo() },
      { id: 2, name: 'CV', src: cvIcon, onClick: () => navigate('/cv') },
      { id: 3, name: 'Email Axel', src: emailIcon, onClick: () => onShowEmail() },
      { id: 4, name: 'Photos', src: imageIcon, onClick: () => navigate('/images')},
      { id: 5, name: 'About Axel', src: aboutMeIcon, onClick: () => navigate('/about-me') },
      { id: 6, name: 'LinkedIn', src: linkedinIcon, onClick: () => window.location.href = 'https://www.linkedin.com/in/axel-schrotti-17b787163?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' },

    ];
  
    return (
      <div style={styles.desktop}>
        {icons.map((icon, index) => (
          <div 
            key={icon.id} 
            style={{ 
              ...styles.iconContainer, 
              ...(hoveredIcon === icon.id ? styles.iconHover : {}), // Apply hover style if hovered
              ...styles[`icon${index + 1}`]
            }}
            onClick={icon.onClick}
            onMouseEnter={() => setHoveredIcon(icon.id)} // Set hovered icon ID
            onMouseLeave={() => setHoveredIcon(null)}   // Reset hovered icon ID
          >
            <img
              src={icon.src}
              alt={icon.name}
              title={icon.name}
              style={styles.icon}
            />
            <span style={styles.iconLabel}>{icon.name}</span>
          </div>
        ))}
      </div>
    );
  }
  
  // Inline styles
  const styles = {
    desktop: {
      display: 'grid', // Use CSS Grid for layout
      gridTemplateColumns: 'repeat(2, 1fr)', // Two columns layout
      gridTemplateRows: 'repeat(4, 100px)', // Four rows with a fixed height
      gridGap: '20px', // Spacing between icons
      justifyItems: 'center', // Center icons in their grid cells
      alignItems: 'start', // Align icons to the start of each cell
      padding: '20px',
      backgroundColor: 'transparent', // No background color for the desktop
      height: '100vh', // Full height of the viewport
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column', // Stack icon and label vertically
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      color: '#fff', // White text for labels
      textAlign: 'center', // Center align text under the icon
      backgroundColor: 'transparent', // Transparent background for the icon container
      transition: 'background-color 0.3s ease', // Smooth transition for hover effect
      padding: '10px',
      borderRadius: '8px',
    },
    icon: {
      width: '64px', // Size of the icon
      height: '64px',
      marginBottom: '8px', // Space between icon and label
      backgroundColor: 'transparent', // Transparent background
    },
    iconLabel: {
      fontSize: '14px', // Font size for the label
      color: '#fff', // Label color (white)
      backgroundColor: 'transparent', // Transparent background for the label
      padding: '2px 5px',
    },
    // Hover style
    iconHover: {
      backgroundColor: '#4A90E2', // Change background color on hover
    },
    // Specific styles to manually place icons in a 4-2 column layout
    icon1: { gridColumn: '1', gridRow: '1' }, // First column, first row
    icon2: { gridColumn: '1', gridRow: '2' }, // First column, second row
    icon3: { gridColumn: '1', gridRow: '3' }, // First column, third row
    icon4: { gridColumn: '1', gridRow: '4' }, // First column, fourth row
    icon5: { gridColumn: '2', gridRow: '1' }, // Second column, first row
    icon6: { gridColumn: '2', gridRow: '2' }, // Second column, second row
  };
  
  export default DesktopIcons;