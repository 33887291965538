import React from 'react';

const Collage = ({ images }) => {
  // Define inline styles
  const collageStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // Adjust the number of columns
    gridGap: '10px', // Space between images
    maxWidth: '1200px', // Max width of the collage
    margin: '0 auto', // Center the collage
  };

  const imageContainerStyle = {
    overflow: 'hidden',
    borderRadius: '10px', // Rounded corners
    position: 'relative', // Position relative for overlay effects if needed
  };

  const collageImageStyle = {
    width: '100%',
    height: 'auto',
    display: 'block',
    transition: 'transform 0.3s', // Add a transition for hover effect
  };

  // Function to generate styles for each image based on index
  const getImageStyle = (index) => {
    switch (index % 2) {
      case 0:
        return { gridColumn: 'span 2', gridRow: 'span 2' }; // Larger image
      case 1:
        return { gridColumn: 'span 2', gridRow: 'span 1' }; // Wider but shorter image
      default:
        return {};
    }
  };

  return (
    <div style={collageStyle}>
      {images.map((image, index) => (
        <div key={index} style={{ ...imageContainerStyle, ...getImageStyle(index) }}>
          <img
            src={image.src}
            alt={image.alt}
            style={collageImageStyle}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')} // Zoom in effect on hover
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')} // Reset scale on mouse leave
          />
        </div>
      ))}
    </div>
  );
};

export default Collage;
