import React, {useState} from 'react';
import BottomMenuBar from './BottomMenuBar';
import SmallBox from './SmallBox';
import Collage from './Collage';

import budva from '../images/Budva.png';
import cambodia from '../images/Cambodia.png';
import cambodia2 from '../images/Cambodia2.png';
import falkenberg from '../images/Falk.png';
import idaho from '../images/Idaho.png';
import iledsm1 from '../images/IleDSM.png';
import iledsm2 from '../images/IleDSM2.png';
import iledsm3 from '../images/IleDSM3.png';
import jackson from '../images/Jackson.png';
import kebnekaise from '../images/Kebne.png';
import middlebury from '../images/Midd.png';
import qutub from '../images/Qutub.png';
import saigon from '../images/Saigon.png';
import stockholm from '../images/Stockholm.png';
import tetons from '../images/Tetons.png';

function MosaicGallery() {
  const imageList = [
    { src: budva, alt: 'Montenegro' },
    { src: cambodia, alt: 'Cambodia' },
    { src: cambodia2, alt: 'Cambodia' },
    { src: falkenberg, alt: 'Sweden' },
    { src: idaho, alt: 'Idaho' },
    { src: iledsm1, alt: 'France' },
    { src: iledsm2, alt: 'France' },
    { src: iledsm3, alt: 'France' },
    { src: jackson, alt: 'Wyoming' },
    { src: kebnekaise, alt: 'Sweden' },
    { src: middlebury, alt: 'Vermont' },
    { src: qutub, alt: 'India' },
    { src: saigon, alt: 'Vietnam' },
    { src: stockholm, alt: 'Sweden' },
    { src: tetons, alt: 'Wyoming' },
  ];

  // Inline styles for the gallery container and images
  const styles = {
    pageContainer: {
      textAlign: 'center',
      padding: '20px',
      backgroundColor: '#f7f7f7',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      marginBottom: '40px',
      color: '#333',
    },
    galleryGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
      gridGap: '10px',
      justifyItems: 'center',
    },
    imageContainer: {
      position: 'relative',
      width: '100%',
      paddingBottom: '100%', // Creates a square container for each image
      overflow: 'hidden',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    image: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'transform 0.3s ease',
    },
    imageHover: {
      transform: 'scale(1.05)',
    },
  };
  const [isEmailVisible, setisEmailVisible] = useState(false);
  const toggleEmailVisibility = () => {
    setisEmailVisible(!isEmailVisible);
  };

  return (
    <div style={styles.pageContainer}>
      <h1 style={styles.title}>Pictures of Wonderful Places</h1>
      <Collage images={imageList}/>

      {isEmailVisible && <SmallBox onClose={toggleEmailVisibility} />}
      <BottomMenuBar onShowBox={toggleEmailVisibility}/>
    </div>
  );
}

export default MosaicGallery;
