import React, { useState } from 'react';
import BottomMenuBar from './BottomMenuBar';
import SmallBox from './SmallBox';
import mekong from '../images/mekong.png';
import me from '../images/me_casual.png';

function AboutMe() {
  const [isEmailVisible, setisEmailVisible] = useState(false);
  const toggleEmailVisibility = () => {
    setisEmailVisible(!isEmailVisible);
  };

  return (
    <>
    <style>
    {`
      body, html {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    `}
  </style>
  <div style={styles.page}>
  <div style={styles.container}>
    <h1 style={styles.heading}>About Me</h1>
      <div style={styles.content}>
        <div style={styles.imageWrapper}>
          <img 
            src={me}  // Using the imported image
            alt="Axel" 
            style={styles.image}
          />
        </div>
        
        <div style={styles.textContent}>
          <p style={styles.text}>
            Hi there! I’m Axel, a developer based in Stockholm, currently working as a consultant at HiQ. My career has mainly been spent in and around industrial projects, where I thrive on tackling complex problems and delivering effective solutions.
            I graduated from Middlebury College in 2023 with a degree in Computer Science and a minor in English Literature. At college, I studied AI, Algorithms, and Software Development — skills that I love to apply in my professional life and as a hobby.
            When I’m not working, you can find me enjoying the great outdoors, exercising, traveling, or experimenting with software like React, which is how I created this webpage! Thanks for stopping by! 
          </p>
        </div>
      </div>

    </div>
    {isEmailVisible && <SmallBox onClose={toggleEmailVisibility} />}
    <BottomMenuBar onShowBox={toggleEmailVisibility}/>
    </div>
  </>
  );
}

// Inline styles for AboutMe component
const styles = {
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    backgroundImage: `url(${mekong})`,  // Correctly set the background image
    backgroundSize: 'cover',           // Ensure the image covers the entire screen
    backgroundPosition: 'center',      // Center the image
    backgroundRepeat: 'no-repeat',     // Prevent the image from repeating

  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: '10px',
    boxSizing: 'border-box',
    zIndex: 998,
  },
  heading: {
    fontSize: '3rem',
    marginBottom: '20px',
    color: 'white',
  },
  content: {
    position: 'relative',  // To allow absolutely positioning the image
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '800px',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
  },
  imageWrapper: {
    position: 'absolute',
    top: '-60px',  // Adjust the position slightly above the div
    left: '-60px',  // Position it to the right edge of the div
    width: '120px',  // Smaller size for the image
    height: '120px',
    borderRadius: '50%',
    overflow: 'hidden',
    boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.15)',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  textContent: {
    flexGrow: 1,
    paddingLeft: '30px',
  },
  text: {
    fontSize: '1.2rem',
    lineHeight: '1.6',
    color: '#555',
  },
};

export default AboutMe;
